import React from "react"
import Layout from "../layout"
import parse from 'html-react-parser';

import Card from "../Card/index";

import "./../layout.scss"
import './styles.scss'

function WorkContent({ data, lang, location }) {

    const portofolioItemEdges = data.allContentfulPortfolioItem.edges;


    const dictionaryList = {
        en: {
            title: 'Discover some incredible products we have built.'
        },
        de: {
        }
    };

    let content;
    lang === "de" ? (content = dictionaryList.de) : (content = dictionaryList.en)

    return (
        <div>
            <Layout lang={lang} showLanguageSelector={false} location={location}>
                <section className="section content-section heroSection-work">
                    <div className="container">
                        <div className="columns">
                            <div class="column is-12">
                                <div class="column" className="">
                                    <h1 className="t-bold">{parse(content.title)}</h1>
                                    <div className="hero__subtitle">
                                        From marketing websites to blockchain applications, you'll be amazed what we can design and build from the ground up.
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section content-section is-verydark">
                    <div className="container card-container">
                        <div className="columns is-variable is-8 is-multiline">
                            {portofolioItemEdges.map(({ node }, i) => (
                                <div className="column is-6 card-column">
                                    <Card subTitle={node.clientName} title={node.title} clientName={node.clientName} link="#" imageUrl={node.titleImage.file.url} />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </Layout>
        </div>
    )
}

export default WorkContent;

