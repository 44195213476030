import React from "react"
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import WorkContent from "../components/workContent/index";

function Work({location, lang, data}) {

  console.log(data);

  return(
    <div>
      <Helmet bodyAttributes={{ class: 'whiteHeader whiteHeader-show' }}>
        <title>Voa Labs | Work</title>
        <meta name="description" content="Voa Labs strategizes, designs & builds digital experiences. We are a digital product studio from Switzerland." />
      </Helmet>
      <WorkContent data={data} lang={lang} location={location} />
    </div>
  )
}

Work.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export const query = graphql`
  query myQuery {
    allContentfulPortfolioItem(sort: {order: DESC, fields: portfolio_item___portfolio_item___order}, filter: {node_locale: {eq: "en-US"}}) {
      edges {
        node {
          id
          clientName
          title
          titleImage {
            id
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default Work;
