import React from 'react';
import parse from 'html-react-parser';
import { Link } from "gatsby"

import './styles.scss'

function Card(props) {

  const slug = props.clientName.replace(/\s+/g, '-').toLowerCase();

  return (
    <Link to={`${slug}`}>
      <div className="BlogCard">
        <div className="card-image Card__image" style={{ backgroundImage: `url(${props.imageUrl})` }}>
        </div>
        <div className="Card__info">
          <div className="content">
            <div className="Card__subTitle">{parse(props.subTitle)}</div>
            <div className="Card__title">{parse(props.title)}</div>
          </div>
        </div>
      </div>
    </Link>

  )
}

export default Card;